@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

body {
  font-family: 'Roboto', sans-serif;
}